const { BaseCollection } = require('./_base.collection');

/**
 * @typedef ShopModel
 * @property {string} id
 * @property {string} userId
 * @property {string} uniqueId
 * @property {string} name
 * @property {string=} photoUrl
 * @property {string=} tax
 * @property {number=} shippingCost
 * @property {string=} consumptionTax
 * @property {string=} topMessage
 * @property {string=} company
 * @property {string=} operationManager
 * @property {string=} address
 * @property {string=} phoneNumber
 * @property {string=} businessHours
 * @property {string=} boxSizeDescription
 * @property {string=} deliveryCompany
 * @property {string=} notificationEmail
 * @property {string=} purchaseConfirmationEmailContent
 * @property {boolean=} enabled
 * @property {boolean=} deletedAt
 * @property {boolean=} status

 */

/**
 * @extends {BaseCollection<ShopModel>}
 */
class ShopCollection extends BaseCollection {
  constructor() {
    super('SHOPS');
  }

  /**
   * @param {string} userId
   */
  findByUserId(userId) {
    return this.find(userId);
  }

  /**
   * @param {string} uniqueId
   * @returns
   */
  findByUniqueId(uniqueId) {
    return this.collection
      .where('uniqueId', '==', uniqueId)
      .get()
      .then((snapshot) =>
        snapshot.docs.length >= 1 ? snapshot.docs[0].data() : null
      );
  }
  findByShopName(shopName) {
    return this.collection
      .where('name', '==', shopName)
      .get()
      .then((snapshot) =>
        snapshot.docs.length >= 1 ? snapshot.docs[0].data() : null
      );
  }
  // findByUniqueIdAndUpdate(name) {
  //   this.collection.update
  // }
}

const shopCollection = new ShopCollection();

module.exports = {
  shopCollection,
  ShopTax: {
    TAX_INCLUDED: 'tax_included',
    TAX_NOT_INCLUDED: 'tax_not_included',
    getLabel(tax) {
      switch (tax) {
        case this.TAX_INCLUDED:
          return '税込';

        case this.TAX_NOT_INCLUDED:
        default:
          return '税別';
      }
    },
  },
  ShopConsumptionTax: {
    ROUND_DOWN: 'round_down',
    ROUND: 'round',
    ROUND_UP: 'round_up',
    getLabel(consumptionTax) {
      switch (consumptionTax) {
        case this.ROUND_DOWN:
          return '切り捨て';

        case this.ROUND:
          return '四捨五入';

        case this.ROUND_UP:
          return '切り上げ';

        default:
          return '';
      }
    },
  },
};

const { getDb } = require('../services/db');

/**
 * @interface BaseCollection
 * @template TModel
 */
class BaseCollection {
  /**
   * @private
   * @type {string}
   */
  _collectionName;

  /**
   * @type {import('firebase').default.firestore.CollectionReference}
   */
  get collection() {
    return getDb().collection(this._collectionName);
  }

  /**
   * @param {string} collectionName
   */
  constructor(collectionName) {
    this._collectionName = collectionName;
  }

  /**
   * @returns {PromiseLike<TModel[]>}
   */
  async all() {
    const snapshot = await this.collection.get();
    return snapshot.docs.map((v) => v.data());
  }

  /**
   * @param {string} id
   * @returns {PromiseLike<TModel | null>}
   */
  async find(id) {
    const snapshot = await this.collection.doc(id).get();
    return snapshot.exists ? snapshot.data() : null;
  }

  /**
   * @param {string[]} ids
   * @returns {PromiseLike<TModel[]>}
   */
  async findListByIds(ids) {
    if (ids.length == 0) {
      return [];
    }

    if (ids.length < 10) {
      const list = await Promise.all(ids.map((id) => this.find(id)));
      return list.filter((v) => !!v);
    } else {
      const snapshot = await this.collection.where('id', 'in', ids).get();
      return snapshot.docs.map((v) => v.data());
    }
  }

  /**
   * @param {TModel} data
   * @returns {string}
   */
  async create(data) {
    try {
      const now = new Date();
      const snapshot = await this.collection.add({
        ...data,
        createdAt: now,
        updatedAt: now,
      });
      await this.collection
        .doc(snapshot.id)
        .set({ id: snapshot.id }, { merge: true });
      return Promise.resolve(snapshot.id);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * @param {string} id
   * @param {TModel} data
   */
  async update(id, data) {
    try {
      await this.collection.doc(id).set(
        {
          ...data,
          updatedAt: new Date(),
        },
        { merge: true }
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Update a document with a specific email.
   * @param {string} fieldName - The email to search for.
   * @param {object} updatedData - The data to update.
   * @return {Promise<void>}
   */
  async updateByFieldName(fieldName, value, updatedData) {
    // Query the collection to find the document with the given email
    try {
      let snapshot = await this.collection.where(fieldName, '==', value).get();
      snapshot = snapshot.docs.map((v) => v.data())[0];
      // Iterate over the documents in the query result
      snapshot
        ? await this.collection.doc(snapshot.id).set(
            {
              ...updatedData,
              updatedAt: new Date(),
            },
            { merge: true }
          )
        : null;
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      return Promise.resolve(); // web hook cause error avoid
    }
  }

  
  /**
   * @param {string} id
   */
  async delete(id) {
    await this.collection.doc(id).delete();
  }
}

module.exports = {
  BaseCollection,
};
